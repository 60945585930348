.rtl-support{
  .phone-input {
    direction: ltr;
    text-align: right;
  }
}

.input-without-border-radius {
  border-radius: unset;
  margin-left: -1px;
}

.my-reports {
  input, select {
    border: 1px solid #f2f4f7;
    color: #646777;
    height: 32px;
    margin-right: 15px;

    &:focus-visible {
      border-color: #4ce1b6;
      outline: none;
    }
  }

  input.editable {
    background: white;
    width: 100%;
  }

  tr {
    td {
      vertical-align: middle;
      form {
        .form__form-group {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  .tableActions {
    padding: 0 0.5rem 0 0;

    .save,
    .cancel {
     margin: 0;
      padding: 3px;
      font-size: 10px;
      width: 60%;
    }

    .cancel {
      margin-top: 4px !important;
    }
  }
}

.settings {
  input, select {
    border: 1px solid #f2f4f7;
    color: #646777;
    height: 28px;
    margin-right: 15px;

    &:focus-visible {
      border-color: #4ce1b6;
      outline: none;
    }
  }
  .table tbody td {
    vertical-align: middle;
  }
  .tableActions {
    padding: 0 0 0 0;
    display: flex;
    align-items: center;

    .save,
    .cancel {
      margin: 0;
      padding: 3px 0;
      font-size: 10px;
      width: 60%;
      white-space: nowrap;
    }

    .cancel {
      margin-left: 5px;
    }
  }
}

/*  
  Disable Auto Zoom in Input "Text" tag - Safari on iPhone
  Ref: https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
*/
@supports (-webkit-overflow-scrolling: touch) {
  .form,
  .table__search,
  .my-reports,
  .form_Settings .form__form-group,
  .settings {
    select,
    textarea,
    input,
    .table__search-input {
      font-size: 16px;
    }
  }

  .form,
  .my-reports,
  .form_Settings {
    .react-select__input {
      font-size: 16px !important;
    }
  }
}