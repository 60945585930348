.table {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    opacity: 0.3;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 1px solid transparent;
  }

  &::-webkit-scrollbar-thumb {

    @include themify($themes) {
      background-color: themed('colorScrollbar');
    }
  }

  @include directify($directions) {
    text-align: directed('left');
  }

  &:last-child {
    margin-bottom: 0;
  }

  table {
    width: inherit;
  }



  thead tr {
    position: sticky;
    top: 0;
  }

  thead th {
    min-height: 45px;
    min-width: 100px;
    padding: 10px;
    top: 0;
    position: sticky;

    &:first-child {
      min-width: 45px;
    }

    @include themify($themes) {
      color: themed('colorText');
      border-bottom-color: themed('colorBorder');
      background: themed('colorBackground');
    }
  }

  tbody td, th {
    border: none;
    padding: 10px;

    @include themify($themes) {
      color: themed('colorTextAdditional');
    }
  }

  tbody {
    tr {
      td {
        span, .tableActions {
          padding-left: 0;
        }
      }
    }
  }

  tbody td {
    border-top: 1px solid #eff1f5;
    vertical-align: middle;
  }

  th, td {
    &:last-child {
      padding-right: 10px;
    }
  }

  .badge {
    font-size: 100%;
  }

  tfoot td {
    min-height: 45px;
    min-width: 100px;
    padding: 10px 0 10px 0;
    font-family: 'Roboto', sans-serif;
    color: #646777;
    font-weight: 700;

    &:first-child {
      min-width: 45px;
      white-space: nowrap
    }
  }

  tfoot tr {
    border-bottom: 1px solid #eff1f5;
  }

  &.table--not-pagination {
    tbody {
      top: 30px;
    }
  }

  &.table--bordered {

    @include directify($directions) {
      text-align: directed('left');
    }

    tr {

      @include themify($themes) {
        border-bottom: 1px solid themed('colorBorder');
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &.table--head-accent {

    thead {

      @include themify($themes) {
        color: themed('colorTextAdditional');
        background-color: themed('colorHover');
      }

      th {
        border-bottom: none;
      }
    }
  }

  &.table-striped {

    tbody tr:nth-of-type(odd) {

      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
  }

  &.table-hover {

    tbody tr {
      transition: all 0.3s;
      cursor: pointer;

      &:hover {

        @include themify($themes) {
          background-color: themed('colorBackgroundBody');
        }
      }
    }
  }

  &.table--empty {
    td {
      padding-top: 36px;
      padding-bottom: 36px;
    }
  }

  .react-table {

    thead tr {
      position: sticky;
      top: 0;
      background: white;
    }
  }

  .react-table__column-header {
    display: flex;
    flex-wrap: wrap;
    align-content: center;

    &:first-child{
      margin-right: 10px;
    }

    &.sortable {
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;

        @include directify($directions) {
          #{directed('right')}: -20px;
        }
      }
    }
  }

  .resizable-table {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;
      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    th,
    td {
      min-width: 100px !important;

      &:first-child {
        min-width: 45px !important;
      }

      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action :none;

        @include themify($themes) {
          background: themed('colorBorder');
        }

        &.isResizing {

          @include themify($themes) {
            background: themed('colorHeaderDataPicker');
          }
        }
      }
    }
  }

  .react-dnd--off {
    pointer-events: none;
  }

  .react-dnd-draggable {
    z-index: 98 !important;

    td {
      width: inherit;

      &:first-child {
        width: 70px;
        min-width: 70px !important;
      }
    }
  }

  .editable-table {
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -ms-user-select: none; /* IE10+ */

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;
      }
    }

    th,
    td {
      min-width: 100px;

      &:first-child {
        max-width: 45px;
        min-width: 45px;
      }
    }

    .table__editable-table--cell-active {
      max-width: inherit;
      border: none;
      outline: 2px solid $color-blue;
      margin: -7px 10px -6px 0;
    }

    .table__edit-form {
      max-width: fit-content;
      border: none;
      border-radius: 0;
      background: transparent;
      font-size: 13px;
      font-family: 'Roboto', sans-serif;
      padding-left: 0;

      @include themify($themes) {
        background-color: themed('colorBackground');
        color: themed('colorTextAdditional');
      }

      &:active {
        border: none;
        outline: 1px solid $color-blue;
      }
    }

    .form-control {

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.variables-table .table.table--not-pagination {
  height: auto;
}
.react-table__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;

  .card__title {
    position: relative;

    .spinner-border {
      color: #4ce1b6;
      top: 0;
      left: auto;
      right: -30px;
      position: absolute;
    }
  }
}

.react-table__customizer {
  padding: 0 20px 20px 15px;
}

.table__search {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
  color: $color-additional;

  .table__search-input {
    max-width: 250px;
    font-size: 12px;
    margin-right: 20px;

    @include themify($themes) {
      color: themed('colorText');
      background: themed('colorBackground');
    }
  }
}

.table__collapse-content {
  width: 275px;
  position: absolute;
  z-index: 101;
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);

  @include directify($directions) {
    #{directed('right')}: 20px;
  }

  @include themify($themes) {
    background: themed('colorBackground');
  }
}

.react-table__highlighter {

  mark {
    background-color: $color-accent;
    padding: 0;
  }
}

.table__collapse-title-wrap {
  padding: 25px 20px 10px 20px;

  p {
    color: $color-additional;
  }

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.table__collapse-item {
  padding: 10px 20px 25px 20px;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.table__collapse-item--tooltip .tooltip {
  z-index: 101;
}

.table__btn {
  font-size: 18px;
  height: 100%;
  padding: 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;

  h5 {
    color: $color-additional;
    margin-right: 10px;
  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: #b1c3c8;
  }

  @include themify($themes) {
    color: themed('colorText');
  }

  @media screen and (max-width: 640px) {
    padding: 0 5px;
  }

  @keyframes beforePulse {

    from {
      width: 7px;
      height: 7px;
    }

    25% {
      width: 13px;
      height: 13px;
    }

    to {
      width: 7px;
      height: 7px;
    }
  }
}

.table__collapse-back {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
}

.table__icon {
  margin: auto 0;
}

.table__toggle {
  margin-left: 0 !important;

  &--disabled {
    opacity: 0.4;
  }
}
.tableActions{
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
    opacity: 1;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    position: absolute;
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border: 1px solid #fff;
    border-radius: 4px;

    /* Position the tooltip text - see examples below! */
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .btn-edit {
    border: 0;
    background: transparent;
  }

  #editIcon {
    span {
      color: white;
    }
  }
}
.displayNonePDF{
  opacity: 0;
  margin: 0 auto;
  width: 60%;
}
.reportPagePDF {
  opacity: 1;
  visibility: unset;
  padding: 15px;
  background-color: white;
  border: 1px solid black;
  h2{
    padding: 15px;
    text-align: center;
  }
  .tablePDF {
    width: 100%;
    tr {
      height: 36px;
      border: 1px solid black;
      border-collapse: collapse;
      text-align: center;
      &:nth-child(1){
        background-color: #ddd;
      }
      td{
        &:nth-child(1) {
          width: 75%;
          padding-left: 12px;
          text-align: left;
        }
        &:nth-child(2n) {
          padding-right: 12px;
          border-left: 1px solid black;
          text-align: right;
        }
        &.alignLeft{
          text-align: left;
        }
      }
    }
  }
}
.form_Settings-Page h5{
  margin-left: 7.5%;
}
.form_Settings{
  display: flex;
  justify-content: space-around;
  &-Left {
    margin-right: 20px;
    width: 100%;
  }
  &-Right {
    margin-left: 20px;
    width: 100%;
  }
  input{
    padding-left: 5px;
    border: 1px solid #ced4da;
    outline: none;
    &:focus, &:active{
      border-color: #4ce1b6;
    }
  }
  &-button {
    display: flex;
    justify-content: flex-end;
    button{
      width: initial;
    }
  }
}
.financialReports{
  .table .resizable-table {
    th, td {
      &:last-child, &:first-child {
        min-width: 150px !important;
      }
    }
    td:nth-child(2){
      padding-left: 10px;
    }
  }
  .tableActions button{
    border: 0;
    background: transparent;
  }
}

.classList.card-body table.react-table{
  th, td{
    min-width: 92px !important;
    &:first-child{
      min-width: 45px !important;
    }
    &:last-child{
      min-width: 120px !important;
    }
  }
}
.MuiTable-root{
  .MuiTableBody-root th.MuiTableCell-root{
    padding-left: 10px;
  }
}

.add-income,
.view-modal {
  @media (max-width: 800px) {
    margin: 28px 15px;
  }
  .modal-content {
    padding: 0;

    .text-right {
      text-align: right;
    }

    table {
      border: 1px solid #dee2e6;

      tr {
        td, th {
          padding: 10px;
        }
      }
    }
    .modal-header {
      justify-content: center;
      border-bottom: 0;
      padding-bottom: 0;

      h5 {
        color: #646777;
        font-weight: 600;
      }
    }
  }
}
